* {
  font-family: Work Sans, Roboto, Source Sans Pro, sans-serif;
  font-size: 100%;
  line-height: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizelegibility;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:root {
  color: #444;
  background-color: #f5f5f5;
  line-height: 1.5;
}

.container {
  max-width: 48em;
  margin: auto;
  padding: 1em 2em;
}

header, footer {
  padding: 2rem 0;
}

h1 {
  font-size: 1.333em;
  font-weight: inherit;
  line-height: 1.5;
}

a {
  color: #000;
}

img {
  width: 100%;
  max-width: 100%;
  display: block;
}

main {
  min-height: 90vh;
  margin: 1em auto;
}

.timeline-filter {
  margin: 0 -.75rem 1.5rem;
}

.timeline-filter button {
  color: inherit;
  background: none;
  border: none;
  outline: none;
  padding: .75rem;
  font-size: .75em;
}

.timeline-filter button, .timeline-item-source {
  color: gray;
  text-transform: uppercase;
  letter-spacing: .05em;
}

.timeline-filter button:hover, .timeline-filter button:focus, .timeline-filter button.active {
  color: #000;
  cursor: pointer;
}

.timeline-item {
  background-color: #fff;
  border-radius: .25em;
  margin: 0 auto 1.5rem;
  position: relative;
  overflow: hidden;
  box-shadow: 0 0 2em #00000006;
}

.timeline-item header, .timeline-item footer {
  padding: .75rem 1rem;
}

.timeline-item header + footer {
  border-top: thin solid #f5f5f5;
}

.timeline-item-image {
  background-color: #a9a9a9;
  background-size: cover;
  padding-bottom: 100%;
  display: block;
}

.dribbble .timeline-item-image {
  padding-bottom: 75%;
}

.timeline-item-image + footer {
  pointer-events: none;
  color: #fff;
  text-shadow: 0 .25rem 3rem #000;
  white-space: nowrap;
  text-overflow: ellipsis;
  background: linear-gradient(#0000, #00000040);
  padding-top: 33.333%;
  padding-bottom: 1rem;
  font-size: .85em;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

.timeline-item header {
  color: gray;
  justify-content: space-between;
  font-size: .75em;
  display: flex;
}

.timeline-item header a {
  text-decoration: none;
}

.footer {
  flex-wrap: wrap;
  align-items: flex-start;
  display: flex;
}

.footer a {
  margin-bottom: .5rem;
  margin-right: 1rem;
  display: inline-block;
}

.footer .logo {
  display: block;
}

.footer .logo img {
  width: 2rem;
}

.footer .logo + nav {
  flex: 1;
  margin-left: 2rem;
}

.footer .logo, .footer nav {
  margin-bottom: 2rem;
}

.footer small {
  margin: 0 0 1em;
  font-size: .75em;
  line-height: 1.5;
  display: block;
}

@media (min-width: 36em) {
  .timeline-items:after {
    content: "";
    clear: both;
    display: table;
  }

  .timeline-item {
    width: calc(50% - 1rem);
    min-height: 7.125rem;
    margin: 0 auto 2rem;
  }

  .timeline-item:nth-child(2n+1) {
    float: left;
    clear: left;
  }

  .timeline-item:nth-child(2n) {
    float: none;
    clear: none;
    margin-right: 0;
  }

  .footer {
    justify-content: space-between;
  }

  .footer .logo {
    flex: .333;
  }

  .footer nav {
    flex: 1;
    margin-left: 3rem;
  }
}

@media (min-width: 48em) {
  :root {
    font-size: 110%;
  }

  .container {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .header, .footer {
    padding: 3rem 0;
  }

  .footer {
    margin-top: 3rem;
  }
}

@media (min-width: 80em) {
  :root {
    font-size: 120%;
  }
}

h1, .timeline-filter, .timeline-item {
  opacity: 0;
  transform-origin: top;
  animation: fadeInDown 1s .5s forwards;
}

.timeline-filter {
  animation-delay: .75s;
}

.loading .timeline-item:nth-child(1) {
  animation-delay: 1s;
}

.loading .timeline-item:nth-child(2) {
  animation-delay: 1.25s;
}

.loading .timeline-item:nth-child(3) {
  animation-delay: 1.5s;
}

.loading .timeline-item:nth-child(4) {
  animation-delay: 1.75s;
}

.loading .timeline-item:nth-child(5) {
  animation-delay: 2s;
}

.loading .timeline-item:nth-child(6) {
  animation-delay: 2.25s;
}

.loading .timeline-item:nth-child(7) {
  animation-delay: 2.5s;
}

.loading .timeline-item:nth-child(8) {
  animation-delay: 2.75s;
}

.loading .timeline-item:nth-child(8) ~ .timeline-item {
  animation-delay: 3s;
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: scale(.95)translateY(-.5rem);
  }

  to {
    opacity: 1;
    transform: scale(1)translateY(0);
  }
}

/*# sourceMappingURL=index.3b1c4bb5.css.map */
